.track-list {
    /* ensure grid uses all allocated real-estate; grid-system adds max-width on each viewport size */
    max-width: 100% !important;
}

.tile {
    background-color: cornflowerblue;
    width: 100%;
    padding-top: 100%;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    animation: slide-up 0.4s ease;
    overflow: hidden;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.cibula {
    background-color: darkorange;
}

.mravce {
    background-color: black;
    color: white;
}

.voda {
    background-color: aqua;
}

.dvere {
    background-color: burlywood;
}

.sol {
    background-color: silver;
}

.ziacka {
    background-color: palegoldenrod;
}

.nozom {
    background-color: darkred;
}

.hahaha {
    background-color: greenyellow;
}

.televizor {
    background-color: darkslategray;
}

.peniaze {
    background-color: green;
}

.mix {
    background-color: deeppink;
}

.nos {
    background-color: orangered;
}

.zavinac {
    background-color: darksalmon;
}

.papuca {
    background-color: saddlebrown;
}

.tile-text {
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    font-size: 2vw;
}

@media only screen and (min-width: 1201px) {
    .tile-text {
        font-size: 1.3vw;
    }
}

@media only screen and (max-width: 1200px) {
    .tile-text {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 992px) {
    .tile-text {
        font-size: 2.2vw;
    }
}

@media only screen and (max-width: 768px) {
    .tile-text {
        font-size: 2.3vw;
    }
}
