.controls {
    position: fixed;
    top: 10px;
    left: 0;
    width: 160px;
    margin-left: -4px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    border-right: 2px solid black;
    background-color: gold;
    border-radius: 4px;
    z-index: 10000;
}

.controls > div {
    cursor: pointer;
    padding: 10px 10px 10px 10px;
    font-weight: bold;
    border-bottom: black dashed 1px;
}

.controls > div:last-child {
    border-bottom: 0;
}


.controls > div:hover {
    background-color: orange;
}

.delayed-run-val {
    width: 40px;
    background-color: gold;
    border: 0;
    border-bottom: 1px dotted black;
    user-input: kokot;
    font-size: medium;
}
