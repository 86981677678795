.search-bar {
    position: fixed;
    top: 10px;
    left: 180px;
    width: 100%;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    border-left: 2px solid black;
    background-color: gold;
    border-radius: 4px;
    z-index: 10000;
}

.search-input {
    background-color: gold;
    width: 100%;
    border: none;
    font-size: 50px;
    margin: 10px;
    outline-width: 0;
}

@media only screen and (max-width: 576px) {
    .search-input {
        font-size: 32px;
        padding-top: 9px;
        padding-bottom: 9px;
    }
}